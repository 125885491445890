import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import localStorageKeys from 'constants/localStorageKeys';
import configureStore from 'redux/configureStore';
import * as types from 'redux/user/userTypes';

import constants, { eventBrandingWebsite } from './constants';
import Auth from './storage/auth/auth';
import {
    checkIsTokenExpired,
    checkMobileApp,
    getMobileAppToken,
} from './common';

// Declare a new interface that extends AxiosRequestConfig
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    tokenCheck?: boolean;
}

const handleLogout = (isEventWebsitePreview: boolean) => {
    if (isEventWebsitePreview) {
        localStorage.setItem(localStorageKeys.FORM_ACCESS_TOKEN, '');
    } else {
        localStorage.setItem(localStorageKeys.ACCESS_TOKEN, '');
        const {
            store: { dispatch },
        } = configureStore;
        dispatch({
            type: types.LOGOUT,
        });
        // Token is expired, perform logout action (e.g., clear token and redirect to login page)
        window.location.href = '/logout';
    }
};

const fetchClient = () => {
    const defaultOptions = {
        baseURL: constants.BASE_URL.API,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    const instance = axios.create(defaultOptions);

    // check if it's a event website form page and token exists then use that token
    const isEventWebsitePreview =
        !!window?.location.pathname.includes(eventBrandingWebsite);

    instance.interceptors.request.use(
        (config: CustomAxiosRequestConfig) => {
            const modifiedConfig = config;
            const {
                tokenCheck = true, // default we set to true so all endpoints will check token validation. If it's not needed for any endpoint will pass this to false from that place
            } = modifiedConfig;
            let token =
                modifiedConfig?.headers?.Authorization ||
                Auth.getStoredCredentials();

            const publicAccessToken = localStorage.getItem(
                localStorageKeys.FORM_ACCESS_TOKEN
            );

            if (isEventWebsitePreview && publicAccessToken) {
                token = publicAccessToken;
            }

            const isMobileApp = checkMobileApp();
            if (isMobileApp) {
                const mobileToken = getMobileAppToken();
                if (mobileToken) token = mobileToken;
            } else {
                const isTokenExpired = tokenCheck && checkIsTokenExpired(token);
                if (isTokenExpired) {
                    handleLogout(isEventWebsitePreview);
                }
            }

            modifiedConfig.headers = {
                Authorization: token ? `Bearer ${token}` : '',
            };

            return modifiedConfig;
        },
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
        (response) => response,
        (err: AxiosError) => {
            if (err?.response) {
                // The request was made, but the server responded with an error status code
                // You can handle specific error status codes here
                if (
                    err?.response?.status === 401 &&
                    err?.response?.data === 'Token expired'
                ) {
                    handleLogout(isEventWebsitePreview);

                    // we don't want to return error at the component level when token is expired so added interval to prevent errors shows at component level
                    return new Promise((_, reject) => {
                        setTimeout(() => {
                            reject(err);
                        }, 5000);
                    });
                }
            }
            return Promise.reject(err);
        }
    );
    return instance;
};

export default fetchClient();
