import { produce } from 'immer';
import constants from 'utils/constants';
import { getFormatedDate } from 'utils/common';
import * as types from './interactiveMapTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_INTERACTIVE_MAP.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_INTERACTIVE_MAP.SUCCESS]: produce(
        (draft, { total, interactiveMaps }) => {
            const records = interactiveMaps.map((item) => ({
                ...item,
                createdAt: getFormatedDate(
                    item.createdAt,
                    constants.GLOBAL.DATE_TIME_FORMATE_FOR_DISPLAY
                ),
            }));
            draft.records = records;
            draft.totalRecords = total;
            draft.loading = false;
        }
    ),
    [types.GET_INTERACTIVE_MAP.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_INTERACTIVE_MAP]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
