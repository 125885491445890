import { combineReducers } from 'redux';
import participantReducer from './participant/participantReducer';
import locationReducer from './location/locationReducer';
import materailReducer from './materials/materialReducer';
import interactiveMapReducer from './interactiveMaps/interactiveMapReducer';
import reportReducer from './reports/reportReducer';
import labelReducer from './label/labelReducer';
import userReducer from './user/userReducer';
import sessionReducer from './session/sessionReducer';
import homeReducer from './home/homeReducer';
import collectionReducer from './collection/collectionReducer';
import engagementToolReducer from './engagementTools/engagementToolsReducer';
import gamificationReducer from './gamifications/gamificationsReducer';
import taskReducer from './gamifications/tasks/taskReducer';
import badgeReducer from './gamifications/badges/badgeReducer';
import assignedPointReducer from './gamifications/assignedPoints/assignedPointsReducer';
import organizationReducer from './SuperAdmin/organization/organizationReducer';
import eventHubReducer from './SuperAdmin/eventHub/eventHubReducer';
import eventReducer from './SuperAdmin/event/eventReducer';
import adminUserReducer from './SuperAdmin/adminUser/adminUserReducer';
import appVersionReducer from './SuperAdmin/appVersion/appVersionReducer';
import dataImportReducer from './dataImport/dataImportReducer';
import thirdPartyImportReducer from './thirdPartyImports/thirdPartyImportsReducer';
import integrationPastHistoryReducer from './thirdPartyImports/integrationPastHistory/integrationPastHistoryReducer';
import moderationReducer from './moderation/moderationReducer';
import communicationReducer from './communication/communicationReducer';
import emailReducer from './email/emailReducer';
import notificationMessages from './notificationMessages/notificationMessagesReducer';
import commonReducer from './common/commonReducer';
import eventWrapperReducer from './participantModule/EventWrapper/eventWrapperReducer';
import breakoutReducer from './breakouts/breakoutReducer';
import customQRCodeReducer from './customQRCodes/customQRCodeReducer';
import meetingReducer from './meetings/meetingsReducer';
import meetingIntervalsReducer from './meetings/intervals/intervalsReducer';
import usersReducer from './users/usersReducer';

const rootReducer = combineReducers({
    report: reportReducer,
    material: materailReducer,
    interactiveMap: interactiveMapReducer,
    location: locationReducer,
    label: labelReducer,
    collection: collectionReducer,
    participant: participantReducer,
    breakout: breakoutReducer,
    customQRCode: customQRCodeReducer,
    eventWrapper: eventWrapperReducer,
    user: userReducer,
    session: sessionReducer,
    home: homeReducer,
    engagementTool: engagementToolReducer,
    gamification: gamificationReducer,
    task: taskReducer,
    badge: badgeReducer,
    assignedPoints: assignedPointReducer,
    meeting: meetingReducer,
    meetingIntervals: meetingIntervalsReducer,
    availabelUsers: usersReducer,
    organization: organizationReducer,
    eventHub: eventHubReducer,
    appVersion: appVersionReducer,
    event: eventReducer,
    adminUser: adminUserReducer,
    dataImport: dataImportReducer,
    thirdPartyImport: thirdPartyImportReducer,
    integrationPastHistory: integrationPastHistoryReducer,
    moderation: moderationReducer,
    communication: communicationReducer,
    email: emailReducer,
    notificationMessages,
    common: commonReducer,
});

export default rootReducer;
